import * as React from "react"
import Layout from "../components/layout/Layout";
import {Helmet} from "react-helmet";

const NotFoundPage = () => {
    return (
        <Layout>
            <Helmet title="404 - Page not found | Mafiaverse Mint">
                <html lang="en"/>
                <meta name="description" content="Page not found"/>
            </Helmet>
            <header>
                <div className="container has-text-centered neon-text" style={{fontSize: "6rem"}}>
                    <h1>404</h1>
                    <h1>Not Found</h1>
                </div>
            </header>
        </Layout>
    )
}

export default NotFoundPage
